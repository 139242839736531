<template>
    <div class="sup_content general-settings">
        <div class="sup_page_heading">
            <h2>LEADS MANAGEMENT </h2>
        </div>
        <div class="sup_inner_content">
            <!--- Show Leads list Start --->
            <DataTable class="p-datatable" :paginator="true" :value="allLeadsData.data" :rows="10" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {currentPage} of {totalPages}" :rowsPerPageOptions="[10,20,50]" :rowHover="true" :loading="allLeadsData.loading" responsiveLayout="scroll" removableSort :filters="filters">
                <template #loading>
                    Loading Leads data. Please wait.
                </template>
                <template #header>
                    <div class="p-d-flex p-jc-between">
                        <div class="sup_list_action">
                            <Button :label="allLeadsData.activityStatus === 'active' ? 'Deactive List': 'Active List'" :icon="['pi', allLeadsData.activityStatus === 'active' ? 'pi-ban' : 'pi-check-circle']" :class="[allLeadsData.activityStatus === 'active' ? 'p-button-danger' : 'p-button-primary', 'p-mr-2']" @click="fetchAllLeads(allLeadsData.activityStatus, 'languageStatus')" />
                        </div>
                        <div class="p-d-flex p-align-center">
                            <span class="p-input-icon-left sup_search_box">
                                <i class="pi pi-search" />
                                <InputText v-model="allLeadsData.search" placeholder="Search Language Text" @keyup.enter="fetchAllLeads(allLeadsData.activityStatus, 'search')"/>
                            </span>
                            <Calendar v-model="allLeadsData.dateRange" placeholder="DD.MM.YY" dateFormat="dd.mm.yy" selectionMode="range" class="sup_button p-ml-2" inputClass="p-inputtext" :manualInput="false" :showIcon="true" @date-select="allLeadsData.dateRange[1] != null ? fetchAllLeads(allLeadsData.activityStatus, 'dateRange') : ''" plceholder="abc" />
                            <Button v-if="allLeadsData.search !== null || allLeadsData.dateRange !== null" icon="pi pi-refresh" class="p-button-sm p-button-dark sup_search_clear p-ml-2" @click="fetchAllLeads(allLeadsData.activityStatus, 'resetFilters')"/>
                            <SplitButton label="Export" icon="pi pi-external-link" class="p-button-secondary p-ml-2" :model="exportType" @click="exportXL('excel')"></SplitButton>
                        </div>
                    </div>
                </template>
                <template #empty>
                    No Lead found...
                </template>

                <Column field="website" header="Website">
                    <template #body="slotProps">
                        <div class="site_nameurl_list">
                            <span class="sup_site_info">{{slotProps.data.Website.siteName}}</span> <a :href="'https://' + [slotProps.data.Website.siteUrl]" class="sup_website_url" target="_blank">{{slotProps.data.Website.siteUrl}}</a>
                        </div>
                        
                    </template>
                </Column>

                <Column field="name" header="Name">
                    <template #body="slotProps">
                        {{`${slotProps.data.firstName} ${slotProps.data.lastName}`}}
                    </template>
                </Column>

                <Column field="email" header="Email">
                    <template #body="slotProps">
                        {{slotProps.data.email}}
                        <!-- <span :class="['lead_status ', slotProps.data.status ? 'lead_status_success' : 'lead_status_danger']"><i :class="['pi ', slotProps.data.status ? 'pi-check' : 'pi-times']"></i>{{slotProps.data.status ? 'Active' : 'In-Active'}}</span> -->
                    </template>
                </Column>

                <Column field="isRequested" header="Request">
                    <template #body="slotProps">
                        <span :class="['lead_status ', slotProps.data.isRequested === 'requested' ? 'lead_status_warning' : slotProps.data.isRequested === 'approved' ?'lead_status_success' : 'lead_status_danger']">
                            <i :class="slotProps.data.isRequested === 'notRequested' ? 'pi pi-times' :
                            slotProps.data.isRequested === 'requested' ? 'pi pi-exclamation-triangle' : 'pi pi-check'"></i>
                            {{slotProps.data.isRequested === 'requested' ? 'Approval Pending' : slotProps.data.isRequested === 'approved' ? 'Approved' : 'Not Requested'}}
                        </span>
                    </template>
                </Column>

                <Column field="createdAt" header="Date of Request">
                    <template #body="slotProps">
                        {{usFormatDate(slotProps.data.createdAt)}}
                    </template>
                </Column>

                <Column field="action" header="Actions">
                    <template #body="slotProps">
                        <Button v-if="slotProps.data.isRequested === 'requested'" type="button" icon="pi pi-check" class="p-button-sm p-button-rounded p-button-success p-mr-1" v-tooltip="'Approve'" @click="deleteLead(slotProps.data)" />
                        <Button v-if="slotProps.data.deletedAt === null" type="button" icon="pi pi-eye" class="p-button-sm p-button-rounded p-button-primary p-mr-1" v-tooltip="'View more'" @click="viewMore(slotProps.data)" />
                        <Button v-if="slotProps.data.deletedAt === null" type="button" icon="pi pi-ban" class="p-button-sm p-button-rounded p-button-danger p-mr-1" v-tooltip="'Deactivate'" @click="confirmationModalfn(slotProps.data, 'delete')" />
                        <Button v-if="slotProps.data.deletedAt !== null" icon="pi pi-undo" class="p-button-secondary p-button-rounded p-button-sm p-mr-1"  v-tooltip="'Restore Language'" @click="confirmationModalfn(slotProps.data, 'restore')" />
                        <Button v-if="slotProps.data.deletedAt !== null" icon="pi pi-trash" class="p-button-danger p-button-rounded p-button-sm"  v-tooltip="'Delete Language'" />
                    </template>
                </Column>
            </DataTable>
            <!--- Show Leads list End --->
        </div>
        <!--- Edit Leads View Modal Start --->
        <Dialog header="Translated text" v-model:visible="leadesModal.status" :modal="true" :style="{width: '600px'}">
            <div class="sup_leads_info">
                <div class="sup_leads_info_inner">
                    <div class="sup_leads_info_title"><i class="pi pi-info-circle"></i>General Info</div>
                    <ul>
                        <li><strong>Device Id:</strong> {{leadesModal.data.deviceId}}</li>
                        <li><strong>Device IP:</strong> {{leadesModal.data.ipAddress}}</li>
                        <li><strong>User Email:</strong> {{leadesModal.data.email}}</li>
                        <li><strong>Address:</strong> {{`${leadesModal.data.streetAddress}, ${leadesModal.data.city}, ${leadesModal.data.state}, ${leadesModal.data.zip}`}}</li>
                    </ul>
                </div>
                <div class="sup_leads_info_inner">
                    <div class="sup_leads_info_title"><i class="pi pi-shield"></i>Data and Privacy Info:</div>
                    <ul class="sup_qustn-answr">
                        <li>
                            <span class="sup_qustn"><strong>Q: Content Send through e-mail?</strong></span>
                            <span class="sup_answr">A: {{leadesModal.data.isContentSent ? 'Yes' : 'No'}}</span>
                        </li>
                        <li>
                            <span class="sup_qustn"><strong>Q: Delete Purchase and card information?</strong></span>
                            <span class="sup_answr">A: {{leadesModal.data.isDeletedCardInformation ? 'Yes' : 'No'}}</span>
                        </li>
                        <li>
                            <span class="sup_qustn"><strong>Q: Third-party Selling Infomation?</strong></span>
                            <span class="sup_answr">A: {{leadesModal.data.isSoldInformation ? 'Yes' : 'No'}}</span>
                        </li>
                        <li>
                            <span class="sup_qustn"><strong>Q: Future offer and update alert?</strong></span>
                            <span class="sup_answr">A: {{leadesModal.data.isReceivedNewsletter ? 'Yes' : 'No'}}</span>
                        </li>
                        <li>
                            <span class="sup_qustn"><strong>Q: Delete all stored data?</strong></span>
                            <span class="sup_answr">A: {{leadesModal.data.isDeletedAllStoredInformation ? 'Yes' : 'No'}}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </Dialog>
        <!--- Edit Leads View Modal End --->
    </div>
</template>

<script>
import { onBeforeMount, reactive } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
import { allLeadsList, allLeadsListExport, deleteFormLead } from '../../../../config/appUrls';
import { apiKey } from "../../../../config/constant";
import userLogout from '../../../../helper/userLogout/userLogout';
import { formatDate, usFormatDate } from "../../../../helper/utility/utility";
const FileSaver = require('file-saver');

export default {
    name: "Leads",
    setup(){
        //Store call
        let store = useStore();

        const allLeadsData = reactive({
            data: "",
            total: null,
            status: null,
            loading: false,
            activityStatus: 'active',
            search: null,
            dateRange: null
        });

        const leadesModal = reactive({
            status: false,
            data: ''
        });

        //Export Language type
        const exportType = reactive([
            {
                label: 'Excel',
                icon: 'pi pi-file-excel',
                command: () => {
                    exportXL('excel');
                }
            },
            {
                label: 'PDF',
                icon: 'pi pi-file-pdf',
                command: () => {
                    exportXL('pdf');
                }
            }
        ]);

        //On before mount function invoke
        onBeforeMount(async()=>{
            fetchAllLeads();
        });

        //Fetch All Language Key
        const fetchAllLeads = async (activityStatus, load) =>{
            let date = null;
            if(load === "languageStatus"){
                allLeadsData.activityStatus = activityStatus === 'active' ? 'de-active' : 'active';
            }

            if(load === "resetFilters"){
                allLeadsData.search = null;
                allLeadsData.dateRange = null;
            }

            if (load === 'dateRange') {
                date = formatDate(allLeadsData.dateRange[0]) + ' ' + formatDate(allLeadsData.dateRange[1]);
            }

            try{
                allLeadsData.loading = !allLeadsData.loading;
                const response = await axios.get(allLeadsList, {
                    params: {
                        status: allLeadsData.activityStatus,
                        search: allLeadsData.search,
                        filterDt: date
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                });
                allLeadsData.data = response.data.data.formLead;

                allLeadsData.loading = !allLeadsData.loading;
            }catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    userLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        const viewMore = (slotData) => {
            leadesModal.status = true;
            leadesModal.data = slotData;
        }

        const exportXL = async(load) => {
            let date = null;
            if (load === 'excel') {
                try{
                    if (allLeadsData.dateRange !== null) {
                        date = formatDate(allLeadsData.dateRange[0]) + ' ' + formatDate(allLeadsData.dateRange[1]);
                    }
                    const response = await axios.get( allLeadsListExport, {
                        responseType: 'arraybuffer',
                        params: {
                            exe: "xlsx",
                            print: false,
                            status: allLeadsData.activityStatus,
                            search: allLeadsData.search,
                            filterDt: date
                        },headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        },
                    });
                    if(response.status === 200) {
                        const dirtyFileName = response.headers['content-disposition'];
                        const regex = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
                        var fileName = dirtyFileName.match(regex)[3];
                        var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        FileSaver(blob, fileName);
                    }
                } catch(err) {
                    console.log('error', err);
                }   
            }
        }

        const deleteLead = async(slotData) => {
            try{
                const options = {
                    method: 'delete',
                    url: deleteFormLead,
                    params: {
                        uuid: slotData.uuid
                    },
                    data: {},
                    headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                    
                };
                await axios(options);
                fetchAllLeads();
                // toast.add({severity:'error', summary:'Deleted!', detail: ' service deleted successfully.', life: 3000});
            } catch(err){
                console.log(err);
            }
        }

        return{
            allLeadsData,
            leadesModal,
            exportType,
            fetchAllLeads,
            usFormatDate,
            viewMore,
            deleteLead
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/style/component/superAdmin/pages/widgetManagement/Leads';
</style>